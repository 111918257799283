@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;800&family=Roboto+Condensed&family=Roboto+Mono:wght@500&family=Roboto:wght@500&display=swap');

html,
body {
  font-family: Roboto Condensed;
  scroll-behavior: smooth;
  background-color: white;
}
