.div {
    max-width: 100vw;
  }
  div .none {
    opacity: 0;
    visibility: hidden;
  }
  
  .div a {
    position: fixed;
    bottom: 40px;
    right: 20px;
    background-color: rgb(21, 97, 109);
    padding: 0.75rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease-in-out;
    z-index: 1;
  }
  .div svg {
    color: white;
    font-size: 1.5rem;
  }
  .div a:hover {
    background-color: #FF7D00;
    transition: 0.2s ease-in-out;
  }
  @media screen and (max-width: 768px) {
    .div svg {
      color: white;
      font-size: 0.8rem;
    }
    .div a {
      bottom: 80px;
      right: 20px;
    }
  }
  